var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "Auth row row--justify-center" }, [
    !_vm.success
      ? _c("div", { staticClass: "Auth__inputs column column--width-auto" }, [
          _c("img", {
            staticClass: "Auth__signin-logo mb-30",
            attrs: {
              alt: "Bobtail logo of the word 'Bobtail'",
              src: require("../../assets/logo.png")
            }
          }),
          !_vm.fromLink && !_vm.emailSent
            ? _c(
                "div",
                [
                  _c("label", { staticClass: "fs-18 fw-med" }, [
                    _vm._v("Forgot password?")
                  ]),
                  _c("p", { staticClass: "fs-16 mt-16 mb-18" }, [
                    _vm._v(
                      "\n        No problem. We'll send you an email to confirm ownership and reset your password.\n      "
                    )
                  ]),
                  _c(
                    "base-input",
                    {
                      ref: "Auth__email-input",
                      staticClass: "width-100",
                      attrs: {
                        instructions: _vm.instructions.EMAIL_INSTRUCTION,
                        label: true,
                        "label-for": "email",
                        placeholder: "Email",
                        type: "email",
                        valid: _vm.emailValid
                      },
                      model: {
                        value: _vm.email,
                        callback: function($$v) {
                          _vm.email = typeof $$v === "string" ? $$v.trim() : $$v
                        },
                        expression: "email"
                      }
                    },
                    [_vm._v("\n        Email\n      ")]
                  ),
                  _c(
                    "base-button",
                    {
                      staticClass: "bg-blue fc-white mt-16 width-100",
                      attrs: { disabled: _vm.submitButtonDisabled },
                      on: {
                        click: _vm.sendResetEmail,
                        keydown: function($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k(
                              $event.keyCode,
                              "enter",
                              13,
                              $event.key,
                              "Enter"
                            )
                          ) {
                            return null
                          }
                          return _vm.sendResetEmail($event)
                        }
                      }
                    },
                    [_vm._v("\n        Send Reset Email\n      ")]
                  )
                ],
                1
              )
            : _vm._e(),
          _vm.emailSent
            ? _c(
                "div",
                [
                  _c("p", { staticClass: "Auth__instructions mb-12" }, [
                    _vm._v(
                      "\n        Password " +
                        _vm._s(_vm.instructions.PASSWORD_INSTRUCTION) +
                        "\n      "
                    )
                  ]),
                  _c(
                    "base-input",
                    {
                      staticClass: "mb-12 width-100",
                      attrs: {
                        instructions: _vm.instructions.EMAIL_INSTRUCTION,
                        label: true,
                        "label-for": "email",
                        placeholder: "Email",
                        type: "email",
                        valid: _vm.emailValid
                      },
                      model: {
                        value: _vm.email,
                        callback: function($$v) {
                          _vm.email = typeof $$v === "string" ? $$v.trim() : $$v
                        },
                        expression: "email"
                      }
                    },
                    [_vm._v("\n        Email\n      ")]
                  ),
                  _c(
                    "base-input",
                    {
                      staticClass: "mb-12 width-100",
                      attrs: {
                        disabled: this.fromLink ? true : false,
                        label: true,
                        "label-for": "code",
                        placeholder: "Enter code"
                      },
                      model: {
                        value: _vm.code,
                        callback: function($$v) {
                          _vm.code = typeof $$v === "string" ? $$v.trim() : $$v
                        },
                        expression: "code"
                      }
                    },
                    [_vm._v("\n        Code\n      ")]
                  ),
                  _c(
                    "base-input",
                    {
                      staticClass: "mb-12 width-100",
                      attrs: {
                        instructions: _vm.instructions.PASSWORD_INSTRUCTION,
                        label: true,
                        "label-for": "password",
                        placeholder: "Enter password",
                        type: "password",
                        valid: _vm.passwordValid
                      },
                      model: {
                        value: _vm.password,
                        callback: function($$v) {
                          _vm.password =
                            typeof $$v === "string" ? $$v.trim() : $$v
                        },
                        expression: "password"
                      }
                    },
                    [_vm._v("\n        Password\n      ")]
                  ),
                  _c(
                    "base-input",
                    {
                      staticClass: "mb-12 width-100",
                      attrs: {
                        instructions:
                          _vm.instructions.PASSWORD_VERIFICATION_INSTRUCTION,
                        label: true,
                        "label-for": "password-verification",
                        placeholder: "Enter password again",
                        type: "password",
                        valid: _vm.passwordVerificationValid
                      },
                      model: {
                        value: _vm.passwordVerification,
                        callback: function($$v) {
                          _vm.passwordVerification =
                            typeof $$v === "string" ? $$v.trim() : $$v
                        },
                        expression: "passwordVerification"
                      }
                    },
                    [_vm._v("\n        Password Verification\n      ")]
                  ),
                  _c(
                    "base-button",
                    {
                      staticClass: "bg-blue fc-white",
                      attrs: { disabled: _vm.submitButtonDisabled },
                      on: { click: _vm.resetPassword }
                    },
                    [_vm._v("\n        Reset Password\n      ")]
                  )
                ],
                1
              )
            : _vm._e()
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }