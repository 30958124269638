<template>
  <div class="Auth row row--justify-center">
    <div
      v-if="!success"
      class="Auth__inputs column column--width-auto"
    >
      <img
        alt="Bobtail logo of the word 'Bobtail'"
        class="Auth__signin-logo mb-30"
        src="../../assets/logo.png"
      >
      <!-- Default view - 1 email input to request reset code/link -->
      <div v-if="!fromLink && !emailSent">
        <label class="fs-18 fw-med">Forgot password?</label>
        <p class="fs-16 mt-16 mb-18">
          No problem. We'll send you an email to confirm ownership and reset your password.
        </p>
        <base-input
          v-model.trim="email"
          class="width-100"
          :instructions="instructions.EMAIL_INSTRUCTION"
          :label="true"
          :label-for="'email'"
          :placeholder="'Email'"
          ref="Auth__email-input"
          :type="'email'"
          :valid="emailValid"
        >
          Email
        </base-input>

        <base-button
          @click="sendResetEmail"
          @keydown.enter="sendResetEmail"
          :disabled="submitButtonDisabled"
          class="bg-blue fc-white mt-16 width-100"
        >
          Send Reset Email
        </base-button>
      </div>

      <!-- View when a user visits the page from clicking on their reset link -->
      <div v-if="emailSent">
        <p class="Auth__instructions mb-12">
          Password {{ instructions.PASSWORD_INSTRUCTION }}
        </p>

        <base-input
          v-model.trim="email"
          class="mb-12 width-100"
          :instructions="instructions.EMAIL_INSTRUCTION"
          :label="true"
          :label-for="'email'"
          :placeholder="'Email'"
          :type="'email'"
          :valid="emailValid"
        >
          Email
        </base-input>

        <base-input
          v-model.trim="code"
          class="mb-12 width-100"
          :disabled="this.fromLink ? true : false"
          :label="true"
          :label-for="'code'"
          :placeholder="'Enter code'"
        >
          Code
        </base-input>

        <base-input
          v-model.trim="password"
          class="mb-12 width-100"
          :instructions="instructions.PASSWORD_INSTRUCTION"
          :label="true"
          :label-for="'password'"
          :placeholder="'Enter password'"
          :type="'password'"
          :valid="passwordValid"
        >
          Password
        </base-input>

        <base-input
          v-model.trim="passwordVerification"
          class="mb-12 width-100"
          :instructions="instructions.PASSWORD_VERIFICATION_INSTRUCTION"
          :label="true"
          :label-for="'password-verification'"
          :placeholder="'Enter password again'"
          :type="'password'"
          :valid="passwordVerificationValid"
        >
          Password Verification
        </base-input>

        <base-button
          @click="resetPassword"
          :disabled="submitButtonDisabled"
          class="bg-blue fc-white"
        >
          Reset Password
        </base-button>
      </div>
    </div>
  </div>
</template>

<script>
// Packages
import {
  CognitoUser,
  // CognitoUserAttribute,
  CognitoUserPool,
} from 'amazon-cognito-identity-js'
import validator from 'validator'
// Components
import BaseButton from '../../components/base-button.vue'
import BaseInput from '../../components/base-input.vue'
// Helpers
import getCognitoInfo from '../../utils/constants'
// Mixins
import {
  ValidationMixin
} from '../../utils/validation-mixin'
import { Users } from '../../utils/api'

const { UserPoolId, ClientId } = getCognitoInfo()

export default {
  name: 'PasswordReset',

  components: {
    BaseButton,
    BaseInput,
  },

  mixins: [ValidationMixin],

  created () {
    this.code = (new URL(window.location.href)).searchParams.get('code')
    // If code exists at 'created', the user came from a link
    if (this.code) {
      this.fromLink = true
    }
  },

  mounted () {
    window.addEventListener('keydown', this.enterBtnEventListener)

    // Autofocus email input
    // Must wait for input to fully render
    this.$nextTick(() => {
      this.$refs['Auth__email-input'].$el.children[0].focus()
    })
  },

  beforeDestroy() {
    window.removeEventListener('keydown', this.enterBtnEventListener)
  },

  data () {
    return {
      code: '',
      email: '',
      emailSent: false,
      emailValid: true,
      fromLink: false,
      poolData: {
        UserPoolId,
        ClientId
      },
      password: '',
      passwordValid: true,
      passwordVerification: '',
      passwordVerificationValid: true,
      submitButtonDisabled: false,
      success: false,
    }
  },

  methods: {
    enterBtnEventListener (e) {
      if (e.keyCode === 13) {
        this.validation()
      }
    },

    resetPassword () {
      if (!this.validation()) return

      this.email = this.email.toLowerCase()

      this.submitButtonDisabled = true
      this.progressStart()
      // Instantiate the cognitoUser info from our user pool
      const cognitoUser = new CognitoUser({
        Username: this.email,
        Pool: new CognitoUserPool(this.poolData),
      })
      // Uses the Cognito ConfirmForgotPassword API
      const newPassword = this.password
      const thisComponent = this
      const verificationCode = this.code
      cognitoUser.confirmPassword(verificationCode, newPassword, {
        onFailure (error) {
          thisComponent.submitButtonDisabled = false
          thisComponent.progressFail()
          thisComponent.requestFailure({ message: error.message })
        },
        onSuccess () {
          thisComponent.submitButtonDisabled = false
          thisComponent.success = true
          thisComponent.progressFinish()
          thisComponent.requestSuccess({
            message: 'Your password has been reset!',
            title: 'Authentication Success'
          })
          thisComponent.$router.push({ name: 'login' })
        },
      })
    },

    async sendResetEmail () {
      if (!this.validation()) return

      this.email = this.email.toLowerCase()

      this.submitButtonDisabled = true
      this.progressStart()
      // Instantiate the cognitoUser info from our user pool
      const cognitoUser = new CognitoUser({
        Username: this.email,
        Pool: new CognitoUserPool(this.poolData),
      })

      try {
        const res = (await Users.canResetPassword(this.email)).data
        if (!res.can_reset) {
          this.progressFinish()
          this.submitButtonDisabled = false
          return this.alertWarning('You cannot reset your password in its current state. Contact an administrator.')
        }
      } catch (err) {
        // some error with b/e function
        // default to allow them to reset
        console.log('error checking if can reset password', err)
      }

      const thisComponent = this
      cognitoUser.forgotPassword({
        onFailure: (error) => {
          console.log('error:', error)
          thisComponent.CError(error, error.stack)
          thisComponent.$Progress.fail()
          if (error.code === 'InvalidParameterException') {
            thisComponent.alertWarning(
              `If you have not changed your password since being given this email/password combo,
              you cannot reset your password without an administrator.`
            )
          } else if (error.code) {
            thisComponent.alertWarning(`${error.message} Contact an administrator.`)
          } else {
            thisComponent.alertWarning(
              'There was an issue an unknown issue sending an email. Contact an administrator.'
            )
          }
          thisComponent.submitButtonDisabled = false
        },
        onSuccess: () => {
          thisComponent.progressFinish()
          thisComponent.requestSuccess({
            message: 'An email has been sent!',
            title: 'Password Reset'
          })
          thisComponent.emailSent = true
          thisComponent.submitButtonDisabled = false
        },
      })
    },

    validation () {
      let valid = true

      if (!this.code) {
        if (this.email && !validator.isEmail(this.email)) {
          this.emailValid = false
          valid = false
        } else {
          this.emailValid = true
        }
      } else {
        if (!this.isValidPassword(this.password)) {
          this.passwordValid = false
          valid = false
        } else {
          this.passwordValid = true
        }

        if (this.password !== this.passwordVerification) {
          this.passwordVerificationValid = false
          valid = false
        } else {
          this.passwordVerificationValid = true
        }
      }

      return valid
    },
  },
}
</script>

<style lang="sass">
@import '../../styles/auth.sass'
</style>